.solution-card
{
    position: relative;
    border: solid #C3C3C3 1px;
    border-radius: 7px;
}

.solution-card img
{
    border-radius: 7px;
}

.solutions-grid
{
    max-width: 1280px;
    display: grid;
    margin: 0 auto;
    grid-template-rows: repeat(30, 44px);
    grid-template-columns: repeat(20, 1fr);
    background: radial-gradient(
  circle at center, /* Posición del centro (20% desde la izquierda, 30% desde arriba) */
  rgba(83, 68, 253, 0.37), 
  rgba(2, 250, 141, 0.23) 22%,
  transparent 50%
);
}

.solution-card-overlay
{
    background: linear-gradient(transparent, #101010);
    display: flex;
    flex-direction: column-reverse;
    bottom: 0;
    right: 0;
    left: 0;
    top: auto;
}

.solution-card-overlay.active
{
    background: linear-gradient(#020202, #55555552 45%, transparent);
    flex-direction: column;
    top: 0;
}

.solution-card-overlay h3
{
    font-size: 25px;
    margin-bottom: 0;
    padding: 0;
    border: none;
    font-weight: 600;
}

.solution-card-overlay.active h3
{
    font-size: 30px;
    padding-bottom: .2em;
    border-bottom: solid 1px #c3c3c3;
    margin-bottom: .5em;
}

.solution-card-overlay p
{
    font-size: 16px;
    font-weight: 300;
    font-family: "Poppins", sans-serif !important;
}

.solution-card-overlay button
{
    font-size: 13px;
    font-weight: 500;
    background:  white;
    color: #272727;
    padding: .2rem .7rem;
    border-radius: 15px;
    display: flex;
    align-items: center;
}

.solutions-header
{
    text-align: center;
    transform: translateY(-16em);
    margin-bottom: -17em;
    padding-bottom: 4em;
    background: linear-gradient(
        to bottom, 
        transparent 80%, 
        rgba(0, 0, 0, 0.5) 
      );
}

/* Ipad air */
@media (max-width: 1000px)
{
    .solutions-grid
    {
        display: flex;
        flex-direction: column;
        gap: 2em;
    }

    .solution-card
    {
        width: 100%;
        height: 40vw;
    }

    .solution-card.active
    {
        height: fit-content;
    }

    .solution-card.active img
    {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .solution-card-overlay.active
    {
        position: relative;
        padding-bottom: 2.5rem;
    }

    
    .solutions-grid
    {
        background: transparent;
    }

}

@media (max-width: 768px)
{

    .solution-card-overlay.active h3, .solution-card-overlay h3
    {
        font-size: 20px;
    }

    .solution-card-overlay.active h3
    {
        padding-bottom: .4em;
        margin-top: .4em;
    }

    .solution-card-overlay.active p
    {
        font-size: 14px;
        font-weight: 200;
    }

    .solution-card-overlay button
    {
        font-size: 14px;
    }
}

/* Ipad pro */
@media only screen and (-webkit-min-device-pixel-ratio: 2) and (min-width: 768px)
{
    .solutions-grid
    {
        grid-template-rows: repeat(30, 48px);
    }
}
