* {
    font-family: "Montserrat", serif !important;

}

body {
    background: #101010;

}

html,
body {
    overflow-x: hidden;

}

.shadow-lg-g {
    --tw-gradient-from: none;
    background: #101010;
    --tw-gradient-to: none;
}


.slick-track {
    gap: 25px;
}

.building-brand-section {

    background: linear-gradient(to bottom,
            rgba(16, 16, 16, 0) 0%,
            /* 5% superior transparente */
            rgba(16, 16, 16, 0) 15%,
            /* Mantiene la transparencia hasta 5% */
            rgba(16, 16, 16, 1) 30%,
            /* Degradado progresivo hasta #101010 */
            rgba(16, 16, 16, 1) 100%);
    /* El resto sólido en #101010 */

}

.mb-menu {

    top: 25vw;
}

.nav-s {
    border: 1px solid gray;
    background: #3c3c3c;
}

.nav-sa {
    background-color: rgb(2, 97, 26);
}

.nav-s:hover {
    background-color: rgb(2, 97, 26);
}



.MuiButtonBase-root svg {
    width: 1.5em;
    height: 1.5em;
}


.enm {
    position: absolute;
    top: -20%;
    pointer-events: none;
    left: 0;
    width: 500px;
    height: 500px;
    background: url("../public/Ellipse.png");
    background-size: cover;
}

.galaxy-section .md:block {
    background: #101010;
    ;
}

.mb-subt li div p {
    font-size: 1rem;
    font-weight: 500;
}

.g-m-i+.g-m-i {
    border-top: 1px solid rgb(190, 190, 190);
}

.galaxy-sing-in {
    --tw-gradient-from: #5640ff2a var(--tw-gradient-from-position);
    --tw-gradient-to: #00fe8c1f var(--tw-gradient-to-position);

}

.galaxy-slide {
    background-image: linear-gradient(to top, #101010 5%, transparent 100%)
}

.galaxy-slide h1 {
    font-size: 3rem;
    ;
}

.galaxy-slide button {
    z-index: 5;

}

.galaxy-slide button span {
    font-size: 1rem;
}

.galaxy-slide button .bg-white {
    position: relative;
    right: -20px;
    ;
}

.galaxy-sing-in h2 {
    font-size: 25px;
    font-weight: 600;
}

.galaxy-sing-in p {
    font-size: 16px;
    font-weight: 200;
}

.element-shadow {
    box-shadow: inset 0px 0px 10px rgba(0, 0, 0, .4);
}

.social-button {
    border: 1px solid white;
}

.social-button:hover span {
    color: #ffffff;
}






@media (max-width: 1249px) {
    .md\:hidden {
        display: block !important;
    }

    .md\:block {
        display: none !important;
    }

    .galaxy-banner .items-start {
        width: 90%;
        ;
    }

}



@media (min-width: 1250px) {


    .partner-section,
    .testimonials-section,
    .solutions-section {
        position: relative;
        top: -200px;
        ;
    }


    .md\:hidden {
        display: none !important;
    }

    .md\:block {
        display: block !important;
    }

    .galaxy-logo {
        margin: 0;
    }

    .galaxy-header-content {
        padding: 0 2%;
        margin-top: 1%
    }

    .building-brand-section {
        --tw-translate-y: -150px;
        transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));

    }



    .text-lg {
        font-size: 18px;
        font-weight: regular;
    }

    .galaxy-menu a:hover {
        border-bottom: 1px solid #00fe8a;
        color: #ffffff;
    }

    .galaxy-solutions-submenu {
        width: 93vw;
        position: fixed;
        top: 80px;
        left: 48.5vw;
        border-radius: 20px;
        z-index: 5;
    }



    .galaxy-solutions-submenu .items-start {
        width: fit-content;
        font-size: 3rem;
        ;
    }

    .galaxy-banner .items-start h1 {
        font-size: 4.5rem;
        width: 70%;
    }

    .galaxy-banner .items-start p {
        font-size: 1rem;
        ;
        width: 70%;
    }

    .galaxy-solutions-submenu li {
        list-style-type: none;
        font-size: 16px;
        margin: 0;
    }

    .galaxy-solutions-submenu h3 {
        font-size: 18px;
        font-weight: 600;
    }

    .galaxy-solutions-submenu p {
        font-size: 16px;
        font-weight: lighter;
    }

    .book-a-demo:hover {
        border: none !important;
        --tw-gradient-to: #02FA8D var(--tw-gradient-to-position) !important;
        --tw-gradient-from: #5444FD var(--tw-gradient-from-position) !important;
    }

    .book-a-demo:hover .rocket-icon {

        --tw-gradient-to: #5444FD var(--tw-gradient-to-position);
        background-clip: text;
    }

    .pagination-g.bg-white {
        background-color: white;
        width: 6rem;
        height: .5rem;
        ;

    }

    .bg-gray-400.pagination-g {
        background-color: white;
        width: 3rem;

    }

    .pagination-g {
        margin-bottom: 20px !important;
    }


}

.close-video {
    background: white;
    color: black;
    width: 65px;
    height: 65px;
    border-radius: 100%;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, .4);
}



.solutions-section .text-lg {
    color: white;
}


.testimonial-arrow {
    font-size: 2rem;
    ;
}

.text-fixed-icons {
    background-color: #707070;
}

.wb-car {
    filter: grayscale(100%) brightness(150%);
    ;
    max-width: 80rem;
}



.text-centered {
    margin: 0 auto;
}



@media only screen and (-webkit-min-device-pixel-ratio: 2) {

    .galaxy-banner {
        height: 45rem;

    }

    .wb-car .carousel-item {
        width: 30vw;
        height: auto;

    }

    .wb-car .carousel-item img {
        width: 100%;

    }

    .sc1 {
        width: 55vw !important;
        height: 90vw;
    }

    .sc2 {
        width: 55vw !important;
        height: 80vw;
    }

    .sc3 {
        width: 92vw !important;
        height: 70vw;
    }

    .sc4 {
        width: 60vw !important;
        height: 90vw;
    }

    .scc {
        height: 150vw;
    }

    .galaxy-banner .items-start {
        width: 80%;
    }

    .why-op {
        padding: 2rem .5rem;
    }

    .galaxy-banner h1 {
        font-size: 2.5rem;
        ;
    }


    .rs-mob a {
        margin-right: .8rem !important;
        margin-left: 0 !important;
    }


    .partner-section p {
        padding: 0;
    }

    .testimonials-mob {
        display: block;
    }

    .pag-ga-cont {
        position: absolute;
        bottom: -40%;
        left: 110%;
        width: 100px;
        transform: translateX(10%) rotate(-90deg);
    }

    .cases-section {
        position: relative;
        top: 8rem;
        background-color: #101010;
    }

    .cases-section .text-center span {
        font-size: 2.2rem;
    }

    .right-10 {
        right: 1rem;
        ;
    }

    .cases-section .font-medium {
        font-size: 2.5rem;
    }

    .cases-section .font-normal {
        font-size: 1rem;
        width: auto;
        margin-bottom: 2rem;
    }

    .cases-section .bg-contain {
        overflow-x: scroll;
        width: 100%;
    }

    .whywork-section {
        position: relative;
        top: 5rem;
    }

    .solutions-section {
        position: relative;
        top: -5rem;
    }

    .solutions-section .font-medium {
        font-size: 2.5rem;
    }

    .whywork-section h2 {
        font-size: 2rem;
    }

    .partner-section h2 {
        font-weight: 500;
        width: 80%;
        font-size: 2rem;
    }

    .whywork-section p {
        font-size: 1rem;
        color: white;
    }

    .text-mob {
        display: block;
    }

    .arrow-testi {
        font-size: 2.5rem;
    }

    .partner-video {
        width: 101vw;
        position: relative;
        left: -9vw;
    }

    .partner-section p {
        width: 80%;
    }

    .whywork-section .container .g-80 {
        width: 80%;
        ;
    }

    .whywork-section .container .g-80 .text-center {
        width: 100%;
    }

    .whywork-section .grid {
        display: flex;
        height: auto;
        overflow-x: scroll;
        width: 100%;
    }



    .whywork-section .grid h3 {
        font-size: 2rem;
        width: 90vw;
        margin: 0 auto;
        position: relative;
        left: 3%;
    }

    .g-why-icon {
        display: block;
        width: 90vw;
        align-items: center;
    }

    .g-why-icon img {
        margin: 0 auto;
        position: relative;
        left: 5%;
    }

    .partner-section .w-full {
        margin: 0;
    }

    .partner-section .g-sep {

        padding-top: 0;

    }


    .partner-section p {
        color: white;
        ;
    }

    .g-f-m {
        background-image: linear-gradient(to bottom,
                #101010 15%,
                transparent 100%);
        height: 8rem;
        border-top: solid 5px #101010;
    }

    .banner-video {
        width: 80%;
        height: 30%;
    }
}



@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
    .whywork-section .grid {
        display: grid;
        overflow-x: hidden;
        overflow-y: hidden;
    }

    .g-why-icon {
        width: -webkit-fill-available;
    }

    .whywork-section h3 {
        width: 100% !important;
        font-size: 1.5rem !important;
    }

    .whywork-section p {
        width: auto;
    }
}